* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --StormGrey-3: #0a0b0d;
  --StormGrey-30: #404851;
  --StormGrey-50: #6b7888;
  --StormGrey-70: #a1adbb;
  --StormGrey-80: #bfc8d2;
  --grey-1: var(--StormGrey-3);
  --grey-2: var(--StormGrey-30);
  --grey-3: var(--StormGrey-50);
  --grey-4: var(--StormGrey-70);
  --grey-5: var(--StormGrey-80);
  --fsz-1: 2.25rem;
  --fsz-2: 1.25rem;
  --fsz-3: 1.125rem;
  --fsz-4: 1rem;
  --fsz-5: .85rem;
  --padding: 1rem;
  --directionSwap: column;
  --headerHeight: 90vh;
  --postTitle: var(--fsz-1);
}

@media (width >= 600px) {
  :root {
    --padding: 2.5rem;
    --headerHeight: 100vh;
    --postTitle: var(--fsz-2);
  }
}

@media (width >= 900px) {
  :root {
    --directionSwap: row;
  }
}

@media (width >= 1024px) {
  :root {
    --padding: 5rem;
  }
}

.about__content, .header, p, .header nav .nav__link, .header__title, a, strong {
  font-size: var(--fsz-3);
  color: var(--grey-2);
  font-weight: 400;
  line-height: 150%;
}

.header nav .nav__link, .header__title, a, strong {
  color: var(--grey-1);
  font-weight: 500;
}

.case__format, .header nav .nav__link span {
  font-size: var(--fsz-5);
  color: var(--grey-3);
}

a {
  text-decoration: none;
}

a:not(.naked) {
  border-bottom: 1px solid var(--grey-4);
  padding-bottom: .125rem;
}

a[target="_blank"]:after, a[target="blank"]:after {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==");
  margin-left: .5rem;
  display: none;
  position: absolute;
}

a:not(.nav__link--disabled):not(.naked):hover {
  border-bottom: 1px solid var(--grey-1);
}

a:not(.nav__link--disabled):hover:after {
  display: inline-block;
}

.header {
  padding: var(--padding) var(--padding) 5rem var(--padding);
  height: var(--headerHeight);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.header__topbar {
  flex-direction: var(--directionSwap);
  display: flex;
}

.header__main {
  flex-direction: var(--directionSwap);
  gap: .5rem;
  display: flex;
}

.header__title {
  flex-direction: var(--directionSwap);
  align-self: self-start;
  gap: .5rem;
  display: flex;
}

.header__title a {
  text-decoration: none;
}

@media (width >= 900px) {
  .header__title {
    flex-direction: row;
    align-items: center;
  }
}

.header__title:before {
  content: "✌️";
  display: inline-block;
}

.header .submit {
  align-self: flex-start;
  margin-top: 1rem;
}

@media (width >= 900px) {
  .header .submit {
    margin-top: 0;
    margin-left: auto;
  }
}

.header .intro {
  max-width: 18rem;
  margin-bottom: 4rem;
}

.header nav .nav {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (width >= 900px) {
  .header nav .nav {
    flex-direction: row;
    gap: 3.2rem;
  }
}

.header nav .nav__link {
  font-size: var(--fsz-2);
}

.header nav .nav__link--active {
  border-bottom: 1px solid var(--grey-1);
}

.header nav .nav__link--disabled {
  color: var(--grey-3);
  cursor: not-allowed;
}

@media (width >= 900px) {
  .about {
    grid-template-columns: 380px 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: end;
    gap: 1rem;
    padding-bottom: 0;
    display: grid;
  }
}

.about__content {
  grid-column: 1;
}

@media (width <= 899px) {
  .about__content {
    margin-bottom: 2rem;
  }
}

.about__content p:first-child {
  margin-bottom: 1rem;
}

.about img {
  width: 100%;
  height: calc(100vh - 5rem);
  object-fit: cover;
  object-position: center right;
  grid-column: 2 / 5;
}

@media (width >= 1024px) {
  .about img {
    grid-column: 3 / 5;
  }
}

@media (width >= 900px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    display: grid;
  }
}

.grid .half {
  grid-column: span 1 !important;
}

.grid img {
  width: 100%;
  background: var(--grey-5);
}

.grid li.col-2 {
  grid-column: span 2;
}

.cases {
  min-height: 80vh;
}

.case__link {
  display: block;
}

.case__link:after {
  margin-left: 0 !important;
}

.case__imgWrapper {
  display: flex;
  overflow: hidden;
}

.case__thumb {
  object-fit: contain;
  object-position: center center;
  max-height: 50vh;
  transition: all .3s ease-in-out;
}

.case__title {
  font-size: var(--fsz-2);
  margin-top: .5rem;
}

.case__format {
  margin-top: .25rem;
}

.case:hover .case__thumb {
  transform-origin: center;
  transform: scale(1.03);
}

body {
  font-family: Inter;
}

main {
  padding: 0 var(--padding) calc(var(--padding)  - 2.5rem) var(--padding);
}

@media (width >= 600px) {
  main {
    transform: translateY(-2.5rem);
  }
}

figcaption {
  visibility: hidden;
}

.mobile-only {
  visibility: hidden;
  display: none;
}

@media (width <= 899px) {
  .mobile-only {
    visibility: visible;
    display: inline-block;
  }
}

.tablet-only {
  visibility: visible;
  display: inline-block;
}

@media (width <= 899px) {
  .tablet-only {
    visibility: hidden;
    display: none;
  }
}

/*# sourceMappingURL=index.b4a7f231.css.map */
